<template>
    <div class="account-types-compontent" v-if="list.length">
        <template v-for="(type, index) in list">
            <div class="account-type-pro" :class="{'margin-l-5': index > 0}" :key="index" v-if="type === 'pro'">
                <svg-icon name="ic-pro" className="fonts-14"></svg-icon> PRO
            </div>
            <div class="account-type-erp" :class="{'margin-l-5': index > 0}" :key="index" v-if="type === 'erp'">
                <svg-icon name="ic-enterprise" className="fonts-14"></svg-icon> {{$t('tools.component_AccountTypes_tips_1')}}
            </div>
        </template>
    </div>
</template>
<script>
import VipPro from '@/components/icon/VipPro.vue';
import VipEnterprise from '@/components/icon/VipEnterprise.vue';
export default {
	components: { VipPro, VipEnterprise, },
    props: {
        list: {
            type: Array,
            default: [],
        }
    }
}
</script>
<style lang="scss" scoped>
.account-types-compontent {
    display: flex;
    font-weight: normal;
    .account-type-pro {
        color: #F3B947;
        font-size: 12px;
    }
    .account-type-erp {
        color: #AA84DA;
        font-size: 12px;
    }
}
</style>
